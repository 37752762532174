import styled from '@emotion/styled';

const Item = styled.li`
  color: #ffffff;
  animation-delay: .35s;
  display: block;
  margin-bottom: 1em;
  min-height: 50px;
  position: relative;
  cursor: pointer;
  max-width: 80vw;
  font-weight: bold;
   
  &:hover {
  background: linear-gradient(to right, #F27121, #E94057, #8A2387);;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  }
`;

export default Item;
