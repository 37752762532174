const getBurgerColor = (isHover, isOpen, headerColor, isMobile) => {
  if (isHover) {
    return 'linear-gradient(to right, #8a2387, #e94057, #f27121)';
  }

  if (isOpen) {
    if (isMobile) {
      return 'black';
    }
    return 'white';
  }

  if (isMobile) {
    if (headerColor === 'black') {
      return 'white';
    } if (headerColor === 'white') {
      return 'black';
    }
  }

  return headerColor;
};

export default getBurgerColor;
