import styled from '@emotion/styled';
import getBurgerColor from './getBurgerColor';


const MiddleLine = styled.div`
  height: 2px;
  width: 20px;
  background: ${props => getBurgerColor(props.isHover, props.isOpen, props.headerColor, props.isMobile)};
  opacity: ${props => (props.isOpen ? 0 : 1)};

  
   ${props => (props.isOfferOpen && !props.isOpen ? `
      transition-timing-function: ease;
      transition-duration: .15s;
      transition-property: transform;
      // transform: rotate(-180deg);
  ` : `
    transition: all 0.2s ease;
  `)};
   
   
   ${props => (props.isOpen ? 'transform:  translateX(-16px)' : '')};
   
     @media (max-width: 768px) {
    ${props => (props.isOpen ? 'background-color: white' : '')};
   }
   
`;

export default MiddleLine;
