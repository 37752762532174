import styled from '@emotion/styled';

const FooterStyled = styled.footer`
    background-color: white;
    color: black;
    text-align: center;
    font-size: 20px;
    display: flex;
    //align-items: center;
    //justify-content: center;
    font-weight: normal;
    padding: 100px;
    
     @media (max-width: 768px) {
    padding: 20px;
    text-align: center;
      }
`;

export default FooterStyled;
