import React from 'react';

const Github = props => (

  <svg viewBox="0 0 121 118" version="1.1">
    <defs>
      <linearGradient x1="0%" y1="2.438743%" x2="98.4586149%" y2="96.0950527%" id="linearGradient-1">
        <stop stopColor="#8A2387" offset="0%" />
        <stop stopColor="#E94057" offset="49.9216631%" />
        <stop stopColor="#F27121" offset="100%" />
      </linearGradient>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Desktop-HD" transform="translate(-759.000000, -4670.000000)">
        <g id="Group-10" transform="translate(759.000000, 4670.000000)">
          <circle style={{ cursor: 'pointer' }} id="Oval" fill="#FFFFFF" cx="60" cy="59" r="59" />
          <path className={props.className} d="M5.68434189e-14,60.387 C5.68434189e-14,87.068 17.303,109.704 41.297,117.689 C44.315,118.248 45.423,116.379 45.423,114.784 C45.423,113.344 45.367,108.587 45.341,103.541 C28.541,107.194 24.996,96.416 24.996,96.416 C22.249,89.436 18.291,87.58 18.291,87.58 C12.812,83.832 18.704,83.909 18.704,83.909 C24.768,84.334 27.961,90.132 27.961,90.132 C33.347,99.364 42.088,96.695 45.534,95.152 C46.076,91.25 47.641,88.584 49.368,87.077 C35.955,85.55 21.855,80.372 21.855,57.233 C21.855,50.64 24.214,45.253 28.077,41.024 C27.45,39.502 25.383,33.361 28.662,25.043 C28.662,25.043 33.733,23.42 45.273,31.233 C50.09,29.895 55.256,29.224 60.388,29.201 C65.52,29.224 70.69,29.895 75.516,31.233 C87.042,23.42 92.106,25.043 92.106,25.043 C95.393,33.361 93.325,39.502 92.698,41.024 C96.57,45.253 98.913,50.64 98.913,57.233 C98.913,80.427 84.786,85.534 71.339,87.029 C73.505,88.903 75.435,92.578 75.435,98.212 C75.435,106.291 75.365,112.794 75.365,114.784 C75.365,116.391 76.452,118.274 79.513,117.681 C103.494,109.687 120.775,87.059 120.775,60.387 C120.775,27.035 93.738,3.26849658e-13 60.388,3.26849658e-13 C27.041,3.26849658e-13 5.68434189e-14,27.035 5.68434189e-14,60.387 Z" id="Fill-52" fillRule="nonzero" />
        </g>
      </g>
    </g>
  </svg>
);

export default Github;
