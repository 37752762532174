import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from './Dropdown';
import DropdownItem from './DropdownItem';
import LangSwitchWrapper from './LangSwitchWrapper';
import { setLang } from '../../../redux/actions';

const LangSwitch = () => {
  const dispatch = useDispatch();
  const isMenuOpen = useSelector(state => state.isMenuOpen);
  const isEstimateMenuOpen = useSelector(state => state.isEstimateMenuOpen);
  const headerColor = useSelector(state => state.headerColor);
  const lang = useSelector(state => state.lang);


  const isOpen = isMenuOpen || isEstimateMenuOpen;
  const color = isOpen ? 'white' : headerColor;
  const onItemClick = () => {
    dispatch(setLang(lang === 'pl' ? 'en' : 'pl'));
  };

  return (
    <LangSwitchWrapper>
      <Dropdown isOpen={isOpen} color={color}>
        <DropdownItem isOpen={isOpen} color={color}>
          {lang.toUpperCase()}
        </DropdownItem>
        <DropdownItem
          color={color}
          onClick={onItemClick}
        >
          {lang === 'pl' ? 'EN' : 'PL'}
        </DropdownItem>
      </Dropdown>
    </LangSwitchWrapper>
  );
};

export default LangSwitch;
