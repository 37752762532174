import React, { useState } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import Select from 'react-select';
import * as emailjs from 'emailjs-com';
import { useDispatch, useSelector } from 'react-redux';
import Overlay from './Overlay';
import OverlayContent from './OverlayContent';
import Input from './Input';
import ButtonStyled from '../shared/ButtonStyled';
import Textarea from './Textarea';
import EstimateLocales from './EstimateLocales.json';
import AutoCol from '../shared/AutoCol';
import EstimateDone from './EstimateDone';
import Form from './Form';
import { setEstimateData } from '../../redux/actions';

const Estimate = ({ isOpen }) => {
  const state = useSelector(state => state);
  const locales = EstimateLocales[state.lang];
  const [isFinished, setIsFinished] = useState(false);
  const dispatch = useDispatch();

  const {
    email,
    name,
    companyName,
    phoneNumber,
    service,
    budget,
    description,
  } = state;

  const sendEmail = () => {
    const templateParams = {
      email,
      name,
      companyName,
      phoneNumber,
      service: service.label,
      budget: budget.label,
      description,
    };

    const serviceId = 'default_service';
    const templateId = 'standard';
    emailjs.send(serviceId, templateId, templateParams);
  };

  const onEstimateClick = () => {
    setIsFinished(true);
    sendEmail();
  };

  const serviceOptions = [
    { value: 'web', label: locales.serviceOptions.web },
    { value: 'mobile', label: locales.serviceOptions.mobile },
  ];


  const budgetOptions = [
    { value: 'max5k', label: locales.budgetOptions.max5k },
    { value: 'max15k', label: locales.budgetOptions.max15k },
    { value: 'max50k', label: locales.budgetOptions.max50k },
  ];

  const colourStyles = {
    singleValue: styles => ({ ...styles, color: 'white' }),
    container: styles => ({
      ...styles,
      margin: 30,
      display: 'inline-block',
    }),
    menu: styles => ({
      ...styles,
      fontSize: 19,
      backgroundColor: 'black',
      color: 'white',
      border: '3px white solid',
      borderRadius: 0,
    }),
    control: styles => ({
      ...styles,
      backgroundColor: 'black',
      border: '3px white solid',
      width: 322,
      height: 50,
      fontSize: 19,
      borderRadius: 0,
      cursor: 'pointer',
    }),
    option: (styles, {
      isDisabled, isFocused, isSelected,
    }) => ({
      ...styles,
      color: 'white',
      backgroundColor: isFocused ? 'grey' : 'black',
      cursor: isDisabled ? 'not-allowed' : 'pointer',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? 'pink' : 'black'),
      },
    }),
  };

  return (
    <Overlay isOpen={isOpen}>
      <Grid>
        <OverlayContent>
          {isFinished ? <EstimateDone setIsFinished={setIsFinished} locales={locales} /> : (
            <Form id="myForm">
              <Row center="xs">
                <AutoCol>
                  <Input
                    type="text"
                    name="name"
                    value={name}
                    onChange={(event) => {
                      dispatch(setEstimateData({ name: event.target.value }));
                    }}
                    placeholder={locales.name}
                  />
                  <Input
                    type="text"
                    name="companyName"
                    value={companyName}
                    onChange={(event) => {
                      dispatch(setEstimateData({ companyName: event.target.value }));
                    }}
                    placeh
                    placeholder={locales.companyName}
                  />

                </AutoCol>
              </Row>
              <Row center="xs">
                <Col>
                  <Input
                    type="text"
                    name="email"
                    value={email}
                    onChange={(event) => {
                      dispatch(setEstimateData({ email: event.target.value }));
                    }}
                    placeholder={locales.email}
                  />
                  <Input
                    type="text"
                    name="phoneNumber"
                    value={phoneNumber}
                    onChange={(event) => {
                      dispatch(setEstimateData({ phoneNumber: event.target.value }));
                    }}
                    placeholder={locales.phoneNumber}
                  />
                </Col>
              </Row>
              <Row center="xs">
                <Col>
                  <Select
                    styles={colourStyles}
                    options={serviceOptions}
                    value={service}
                    onChange={(selectedOption) => {
                      dispatch(setEstimateData({ service: selectedOption }));
                    }}
                    placeholder={locales.service}
                  />
                  <Select
                    styles={colourStyles}
                    options={budgetOptions}
                    value={budget}
                    onChange={(selectedOption) => {
                      dispatch(setEstimateData({ budget: selectedOption }));
                    }}
                    placeholder={locales.budget}
                  />
                </Col>
              </Row>
              <Row center="xs">
                <Textarea
                  width={680}
                  height={150}
                  type="text"
                  name="description"
                  value={description}
                  onChange={(event) => {
                    dispatch(setEstimateData({ description: event.target.value }));
                  }}
                  placeholder={locales.description}
                />
              </Row>
              <Row center="xs">
                <ButtonStyled onClick={onEstimateClick} style={{ width: '50%' }} color="white">{locales.button}</ButtonStyled>
              </Row>
            </Form>
          )}


        </OverlayContent>
      </Grid>
    </Overlay>
  );
};

export default Estimate;
