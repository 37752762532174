import styled from '@emotion/styled';

const FollowText = styled.div`
  font-size: 15px;
  color: ${props => props.color};
  text-align: left;
  
   @media (max-width: 768px) {
    //font-size: 50px;
    text-align: center;
  }
`;

export default FollowText;
