import styled from '@emotion/styled';

const Link = styled.a`
display: block;
margin-bottom: 5px;
  font-size: 15px;
  font-weight: normal;
  text-align: left;
  cursor: pointer;
  text-decoration: none;
  color: black;
  
   &:hover {
 
  background: #8A2387;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #F27121, #E94057, #8A2387);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #F27121, #E94057, #8A2387); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  }
  
   @media (max-width: 768px) {
   text-align: center;
  }

`;

export default Link;
