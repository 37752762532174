import styled from '@emotion/styled';

const DropdownItem = styled.li`
color: ${props => (props.color === 'white' ? 'black' : 'white')};
  cursor: pointer;
  display: block;
  padding: 0 9px;
  line-height: 40px;
  
  &:last-child {
  
  border-top: 1px solid #EEE;
  }

  &:hover{
    //background-color: #0005FF;
    background: linear-gradient(to right, #8a2387, #e94057, #f27121);;
  }
  
     @media (max-width: 768px) {
    color:  ${props => (props.color)};
    
     ${props => (props.isOpen ? 'color: black;' : '')};
   }
`;

export default DropdownItem;
