import styled from '@emotion/styled';

const Form = styled.form`

   @media (max-width: 768px) {
 margin-top: 60px;
  }
`;

export default Form;
