import styled from '@emotion/styled';

const LogoText = styled.p`
display: inline-block;
  font-size: 25px;
  color: black;
  font-weight: bold;
  text-align: left;
  line-height: 2;
  vertical-align: top;
  margin-left: 10px;
`;

export default LogoText;
