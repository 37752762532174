import '../../reset.css';
import React, { useEffect } from 'react';
import * as emailjs from 'emailjs-com';
import { useDispatch, useSelector } from 'react-redux';
import { Global } from '@emotion/core';
import { ScrollingProvider } from 'react-scroll-section';
import Header from '../header/Header';
import Estimate from '../estimate/Estimate';
import Footer from '../footer/Footer';
import { setHeaderColor } from '../../redux/actions';
import GlobalStyles from '../../GlobalStyles';
import Seo from './Seo';

const App = ({ children, is404 }) => {
  const state = useSelector(state => state);
  const dispatch = useDispatch();
  emailjs.init('user_zpbfdMVAN6jQGtp52Xche');


  const changeHeaderColorOnScroll = () => {
    const current = window.scrollY;
    const viewPortHeight = window.innerHeight;
    const offset = 43.2;

    const setHeaderColorr = (color) => {
      if (state.headerColor !== color) {
        dispatch(setHeaderColor(color));
      }
    };


    if (is404) {
      setHeaderColorr('white');
      return;
    }

    if (state.isMobile) {
      if (state.isOfferOpen) {
        const webHeight = document.getElementById('web').offsetHeight;

        if (current > 0 && current < webHeight - 86.4) {
          setHeaderColorr('black');
        } else if (current > webHeight - 86.4) {
          setHeaderColorr('white');
        }
      } else if (current > 0 && current < viewPortHeight - 86.4) {
        setHeaderColorr('black');
      } else if (current > viewPortHeight - 86.4 && current < viewPortHeight * 2 - 86.4 * 2) {
        setHeaderColorr('white');
      } else if (current > viewPortHeight - 86.4 * 2 && current < viewPortHeight * 3 - 86.4 * 3) {
        setHeaderColorr('black');
      } else if (current > viewPortHeight - 86.4 * 3 && current < viewPortHeight * 4 - 86.4 * 4) {
        setHeaderColorr('white');
      } else if (current > viewPortHeight - 86.4 * 4 && current < viewPortHeight * 5 - 86.4 * 5) {
        setHeaderColorr('black');
      } else if (current > viewPortHeight - 86.4 * 5 && current < viewPortHeight * 6 - 86.4 * 6) {
        setHeaderColorr('white');
      }
    } else if (current > 0 && current < viewPortHeight - offset) {
      setHeaderColorr('black');
    } else if (current > viewPortHeight - offset && current < viewPortHeight * 2 - offset) {
      setHeaderColorr('white');
    } else if (current > viewPortHeight - offset && current < viewPortHeight * 3 - offset) {
      setHeaderColorr('black');
    } else if (current > viewPortHeight - offset && current < viewPortHeight * 4 - offset) {
      setHeaderColorr('white');
    } else if (current > viewPortHeight - offset && current < viewPortHeight * 5 - offset) {
      setHeaderColorr('black');
    } else if (current > viewPortHeight - offset && current < viewPortHeight * 6 - offset) {
      setHeaderColorr('white');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeHeaderColorOnScroll);

    if (is404) {
      dispatch(setHeaderColor('white'));
    }

    return () => {
      window.removeEventListener('scroll', changeHeaderColorOnScroll);
    };
  }, [state.headerColor]);

  return (
    <>
      <Global styles={GlobalStyles} />
      <Seo />
      <ScrollingProvider>
        <Header />
        <Estimate isOpen={state.isEstimateMenuOpen} />
        {children}
        <Footer />
      </ScrollingProvider>
    </>
  );
};

export default App;
