import styled from '@emotion/styled';

const LangSwitchWrapper = styled.div`
  position: absolute;
  right: 5.5rem;
  top: 1.5rem;
  z-index: 100;
`;

export default LangSwitchWrapper;
