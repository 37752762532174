import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import Wrapper from './Wrapper';
import Menu from './Menu/Menu';
import TopLine from './TopLine';
import MiddleLine from './MiddleLine';
import BottomLine from './BottomLine';
import {
  setHeaderColor,
  setIsEstimateMenuOpen,
  setIsMenuOpen,
  setIsOfferOpen,
} from '../../../redux/actions';

const BurgerButton = () => {
  const [isHover, setIsHover] = useState(false);
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const {
    headerColor,
    isMenuOpen,
    isEstimateMenuOpen,
    isOfferOpen,
    isMobile,
    lang,
  } = state;


  const isOpen = isMenuOpen || isEstimateMenuOpen;

  const onClick = async () => {
    if (isOfferOpen) {
      if (isEstimateMenuOpen) {
        return dispatch(setIsEstimateMenuOpen(false));
      }

      navigate('/');
      window.scrollTo(0, 0);
      dispatch(setHeaderColor('black'));

      dispatch(setIsOfferOpen(false));
    } else if (isEstimateMenuOpen) {
      dispatch(setIsEstimateMenuOpen(!isEstimateMenuOpen));
    } else {
      dispatch(setIsMenuOpen(!isMenuOpen));
    }
  };

  const menuItems = [
    {
      path: 'intro',
      label: lang === 'pl' ? 'Intro' : 'Intro',
    },
    {
      path: 'services',
      label: lang === 'pl' ? 'Usługi' : 'Sevcies',
    },
    {
      path: 'technologies',
      label: lang === 'pl' ? 'Technologie' : 'Technologies',
    },
    {
      path: 'design',
      label: lang === 'pl' ? 'Design' : 'Design',
    },
  ];

  const lineProps = {
    isMobile,
    isOfferOpen,
    headerColor,
    isOpen,
    isHover,
  };

  return (
    <>
      <Menu
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        menuItems={menuItems}
      />
      <Wrapper
        isOfferOpen={isOfferOpen}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={onClick}
      >
        <TopLine
          {...lineProps}
        />
        <MiddleLine
          {...lineProps}
        />
        <BottomLine
          {...lineProps}
        />
      </Wrapper>
    </>
  );
};

export default BurgerButton;
