import {
  SET_ESTIMATE_DATA,
  SET_HEADER_COLOR,
  SET_IS_ESTIMATE_MENU_OPEN,
  SET_IS_MENU_OPEN,
  SET_IS_OFFER_OPEN,
  SET_LANG,
} from '../actionTypes';

export const setLang = value => ({
  type: SET_LANG,
  value,
});

export const setHeaderColor = value => ({
  type: SET_HEADER_COLOR,
  value,
});

export const setIsOfferOpen = value => ({
  type: SET_IS_OFFER_OPEN,
  value,
});

export const setIsEstimateMenuOpen = value => ({
  type: SET_IS_ESTIMATE_MENU_OPEN,
  value,
});

export const setIsMenuOpen = value => ({
  type: SET_IS_MENU_OPEN,
  value,
});

export const setEstimateData = data => ({
  type: SET_ESTIMATE_DATA,
  data,
});
