import styled from '@emotion/styled';
import getBurgerColor from './getBurgerColor';


const BottomLine = styled.div`
  height: 2px;
  width: 20px;
  background: ${props => getBurgerColor(props.isHover, props.isOpen, props.headerColor, props.isMobile)};
  
  
  
  ${props => (props.isOfferOpen && !props.isOpen ? `
    transform: translate3d(6.5px,-2.5px,0) rotate(-45deg) scaleX(.7);
    transition-timing-function: ease;
    transition-duration: .15s;
    transition-property: transform;
    ` : `
  transition: all 0.2s ease;    
  transform-origin: top left;
 
  `)};
  
   margin-top: 5px;
  
  ${props => (props.isOpen ? 'transform:  translateX(-1px) rotate(-45deg)' : '')};
  
    @media (max-width: 768px) {
    ${props => (props.isOpen ? 'background-color: white' : '')};
   }
`;

export default BottomLine;
