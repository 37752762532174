import styled from '@emotion/styled';
import Github from '../../assets/svg/Github';

const StyledGithub = styled(Github)`
cursor: pointer;
fill: ${props => (props.isHover ? 'url(#linearGradient-1)' : 'black')};


`;

export default StyledGithub;
