import styled from '@emotion/styled';

const HeaderWrapper = styled.header`
  z-index: 99;
  color: white;
  display: flex;
  position: fixed;
  right: 0;
  width: 100%;
  justify-content: flex-end;
  justify-items: center;
   background-color: ${props => (props.isMobile ? props.headerColor : 'transparent')};
  padding-top: 2.7rem;
  padding-bottom: 2.7rem;
`;

export default HeaderWrapper;
