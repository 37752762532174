import React from 'react';
import { Grid, Row } from 'react-flexbox-grid';
import { inject, observer } from 'mobx-react';
import { useDispatch, useSelector } from 'react-redux';
import AutoCol from '../shared/AutoCol';
import MainText from '../shared/MainText';
import ButtonStyled from '../shared/ButtonStyled';
import Tick from '../../assets/svg/Tick';
import EstimateLocales from './EstimateLocales.json';
import { setIsEstimateMenuOpen } from '../../redux/actions';

const EstimateDone = ({
  setIsFinished,
}) => {
  const state = useSelector(state => state);
  const dispatch = useDispatch();
  const locales = EstimateLocales[state.lang];


  const onClick = () => {
    dispatch(setIsEstimateMenuOpen(false));
    setIsFinished(false);
  };

  return (
    <Grid>
      <Row center="xs">
        <AutoCol xs={6} md={4}>
          <MainText color="white">
            {locales.successText}
          </MainText>
        </AutoCol>
        <AutoCol md={3} xs={4}>
          <Tick />
        </AutoCol>


      </Row>
      <Row center="xs">
        <AutoCol xs={6} md={4}>
          <ButtonStyled onClick={onClick} style={{ margin: 'auto', marginTop: 100 }} color="white">
            {locales.button2}
          </ButtonStyled>
        </AutoCol>
      </Row>

    </Grid>
  );
};


export default EstimateDone;
