import React from 'react';

const Modernware = props => (
  <svg width={props.size} height={props.size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 299.21 344.03">
    <polygon
      points="299.21 258.03 149.6 344.04 0 258.03 0 86.01 149.6 0 299.21 86.01 299.21 258.03"
    />
    <polygon
      fill="#FFF"
      className="cls-1"
      points="151.77 52.17 47.48 112.27 47.48 231.77 98.35 260.38 98.35 142.28 128.87 125.33 128.87 277.56 152.64 291.86 179.75 275.65 179.75 125.33 210.27 142.28 210.27 258.48 256.06 231.77 256.06 112.27 151.77 52.17"
    />
  </svg>
);

export default Modernware;
