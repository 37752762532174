import styled from '@emotion/styled';

const ButtonStyled = styled.button`
display: block;
  //width: 250px;
  width: 50%;
  height: 3em;
   box-sizing: border-box;
  //padding-left: 20px;
  //padding-right: 20px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  margin: 1em 1em 1em 0;
  border: 5px ${props => props.color} solid;
  text-transform: uppercase;
  
  color: ${props => props.color};
  background: transparent;
  &:hover {
  color: white;
  //background: black;
  background: linear-gradient(to right, #8a2387, #e94057, #f27121);
  border: 0;
  }
  
  ${props => (props.toRight ? 'float: right' : '')};
  
   @media (max-width: 768px) {
    width: 100%;
    float: initial;
  }
  
  
`;

export default ButtonStyled;
