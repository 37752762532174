import styled from '@emotion/styled';

const SectionStyled = styled.section`
  min-height: 100vh ;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: ${props => props.background};
  max-width: 100vw;
  
   @media (max-width: 768px) {
      &:first-of-type {
    min-height: 100vh;
      
      }
        min-height: calc(100vh - 86.4px);
  }
  
 
`;

export default SectionStyled;
