import React, { useState } from 'react';
import { Grid, Row } from 'react-flexbox-grid';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import FooterStyled from './FooterStyled';
import Modernware from '../../assets/svg/Modernware';
import StyledGithub from './StyledGithub';
import AutoCol from '../shared/AutoCol';
import LogoText from './LogoText';
import FollowText from './FollowText';
import Title from './Title';
import Link from './Link';
import BehanceStyled from './BehanceStyled';
import FooterLocales from './FooterLocales.json';
import { setHeaderColor, setIsOfferOpen } from '../../redux/actions';

const Footer = () => {
  const state = useSelector(state => state);
  const dispatch = useDispatch();

  const locales = FooterLocales[state.lang];
  const [isHoverGithub, setIsHoverGithub] = useState(false);
  const [isHoverBehance, setIsHoverBehance] = useState(false);

  const openInNewTab = (url) => {
    const win = window.open(url, '_blank');
    win.focus();
  };
  return (
    <FooterStyled>
      <Grid style={{ padding: 0 }}>
        <Row cemter="xs" start="md">
          <AutoCol md={3}>
            <div style={{
              width: 50, height: 50, display: 'inline-block', marginBottom: 15,
            }}
            >
              <Modernware />
            </div>
            <LogoText>modernware</LogoText>

            <FollowText>
              {locales.contact}
            </FollowText>
            <Link href="mailto:hello@modernware.io">
                hello@modernware.io
            </Link>
          </AutoCol>
          <AutoCol md={3}>
            <Title>{locales.services}</Title>
            <Link onClick={async () => { await dispatch(setIsOfferOpen(true)); dispatch(setHeaderColor('black')); navigate('/offer'); window.location.hash = 'web'; }}>{locales.web}</Link>
            <Link onClick={async () => { await dispatch(setIsOfferOpen(true)); dispatch(setHeaderColor('white')); navigate('/offer'); window.location.hash = 'mobile'; }}>{locales.mobile}</Link>
          </AutoCol>

          <AutoCol md={3}>
            <Title>{locales.follow}</Title>
            <div onClick={() => openInNewTab('https://www.behance.net/modernware')} onMouseEnter={() => setIsHoverBehance(true)} onMouseLeave={() => setIsHoverBehance(false)} style={{ width: 50, display: 'inline-block', marginRight: 15 }}>
              <BehanceStyled isHover={isHoverBehance} />
            </div>
            <div onClick={() => openInNewTab('https://github.com/modernware')} onMouseEnter={() => setIsHoverGithub(true)} onMouseLeave={() => setIsHoverGithub(false)} style={{ width: 50, display: 'inline-block' }}>
              <StyledGithub isHover={isHoverGithub} />
            </div>
          </AutoCol>
        </Row>
      </Grid>

    </FooterStyled>
  );
};

export default Footer;
