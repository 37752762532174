import styled from '@emotion/styled';

const Dropdown = styled.ul`

background-color: ${props => props.color};
  display: inline-block;
  padding: 0;
  height: 40px;
  overflow: hidden;
  transition: all .3s ease;
  vertical-align: top;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 150px;

  &:hover {
    height: 80px;
  }
  
   @media (max-width: 768px) {
    background-color: ${props => (props.color === 'white' ? 'black' : 'white')};
    
    ${props => (props.isOpen ? 'background-color: white' : '')};
   }
`;

export default Dropdown;
