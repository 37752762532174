import styled from '@emotion/styled';

const Title = styled.div`
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: left;
  //text-transform: uppercase;
  
   @media (max-width: 768px) {
    font-size: 20px;
    text-align: center;
  }
`;

export default Title;
