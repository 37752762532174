import styled from '@emotion/styled';

const Wrapper = styled.div`
  width: 20px;
  height: 20px;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 3rem;
  top: 2rem;
  ${props => (props.isOfferOpen ? ' transform: rotate(-180deg);' : '')};
`;

export default Wrapper;
