import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-scroll';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import Overlay from './Overlay';
import Navigation from './Navigation';
import MenuStyled from './MenuStyled';
import Item from './Item';
import { setHeaderColor, setIsMenuOpen, setIsOfferOpen } from '../../../../redux/actions';

const Menu = ({ menuItems }) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state);

  const onBurgerClick = () => {
    navigate('/');
    window.scrollTo(0, 0);
    dispatch(setHeaderColor('black'));
    dispatch(setIsMenuOpen(false));
    dispatch(setIsOfferOpen(false));
  };

  return (
    <Overlay
      isMenuOpen={state.isMenuOpen}
      id="overlay"
    >
      <Navigation>
        <MenuStyled>
          {menuItems.map((menuOption) => {
            if (menuOption.path === 'back') {
              return (
                <Item
                  key={menuOption.label}
                  spy
                  aria-hidden
                  onClick={onBurgerClick}
                >
                  {menuOption.label}
                </Item>
              );
            }
            return (
              <Link to={menuOption.path} key={menuOption.path} smooth>
                <Item
                  key={menuOption.label}
                  spy
                  aria-hidden
                  onClick={() => { dispatch(setIsMenuOpen(false)); }}
                >
                  {menuOption.label}
                </Item>
              </Link>
            );
          })}
        </MenuStyled>
      </Navigation>
    </Overlay>
  );
};

Menu.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Menu;
