import React from 'react';
import { useSelector } from 'react-redux';
import LangSwitch from './LangSwitch/LangSwitch';
import BurgerButton from './BurgerButton/BurgerButton';
import HeaderWrapper from './HeaderWrapper';
import { isBrowser } from '../../utils';

const Header = () => {
  const state = useSelector(state => state);


  return (
    <HeaderWrapper
      headerColor={state.headerColor}
      isMobile={state.isMobile}
    >
      <LangSwitch />
      <BurgerButton />
    </HeaderWrapper>
  );
};

export default Header;
