import { css } from '@emotion/core';

const GlobalStyles = css`
  body{
    font-family: arial, helvetica,serif;
    color: black;
    background-color: black;
  }
  
`;

export default GlobalStyles;
